<template>
  <div class="scrolllayout">

    <div class="scrolllayout-flex">
      <nav1></nav1>


      <div class="contact" v-if="type==1" style="display: flex;flex-direction: column;flex: 1">


        <div class="contactposition" style="flex: 1">

          <div style="display: flex;flex-direction: column;flex: 1">
            <div style="display: flex;flex-direction: row;flex: 1;font-size: 14px!important;">

              <div v-if="setting.area"
                   style=";background-color: #1BBDA9;width: 280px;position: relative">
                <div class="zdh">
                  <div style="display: flex;flex-direction: row;align-items: center;padding: 10px 0">
                    <div class="el-icon-headset" style="font-size: 40px"></div>
                    <div style="display: flex;flex-direction: column;flex: 1;padding-left: 10px">
                      <div>客服热线</div>
                      <div>{{ setting.area.mobile ? setting.area.mobile : '' }}</div>
                    </div>
                  </div>
                  <div style="display: flex;flex-direction: row;align-items: center;padding: 10px 0">
                    <div class="el-icon-message" style="font-size: 40px"></div>
                    <div style="display: flex;flex-direction: column;flex: 1;padding-left: 10px">
                      <div>电子邮箱</div>
                      <div>{{ setting.area.email ? setting.area.email : '' }}</div>
                    </div>
                  </div>
                  <div style="display: flex;flex-direction: row;align-items: center;padding: 10px 0">
                    <div class="el-icon-map-location" style="font-size: 40px"></div>
                    <div style="display: flex;flex-direction: column;flex: 1;padding-left: 10px">
                      <div>地址</div>
                      <div>{{ setting.area.address ? setting.area.address : '' }}</div>
                    </div>
                  </div>
                  <div style="display: flex;flex-direction: row;margin-top: 20px">
                    <div style="flex: 1">
                      <img style="width: 100%" src="../images/gzhewm.jpg"/>
                      <div style="font-size: 16px;text-align: center">公众号</div>
                    </div>
                    <div style="flex: 1;margin-left: 20px">
                      <img style="width: 100%" src="../images/kfewm.jpg"/>
                      <div style="font-size: 16px;text-align: center">客服号</div>
                    </div>
                  </div>

                </div>
              </div>

              <div style="flex: 1;height: 400px;position: relative">
              <div id="container" class="ydh"
                   style="width: 100%;height: 100%"></div>
              </div>

            </div>
            <div style="flex: 1">
              <div v-if="setting.contact_us" class="detail" v-html="setting.contact_us"></div>
            </div>
          </div>
        </div>


        <!--        <div class="contactposition">
                  <div style="text-align: center;font-size: 27px;color: #333;font-weight: bold;padding: 20px 0">联系我们</div>

                  <div style="display: flex;flex-direction: column">
                    <div v-if="setting" style="padding: 20px;display: flex;flex-direction: row">
                      <div style="flex: 1">
                        <div
                            style="align-items: center;border-radius: 10px;padding: 10px;height: 60px;box-shadow: 0 0 9px rgba(0,0,0,0.3);display: flex;flex-direction: row;width: 200px">
                          <div class="el-icon-headset" style="font-size: 40px"></div>
                          <div style="margin-left: 20px;font-size: 16px">
                            <div style="font-size: 15px;font-weight: bold">客服热线</div>
                            <div>{{setting.area.mobile}}</div>
                          </div>
                        </div>
                      </div>
                      <div style="flex: 3;display: flex;justify-content: center">

                        <div
                            style="align-items: center;border-radius: 10px;padding: 10px;margin: 0 30px;height: 60px;box-shadow: 0 0 9px rgba(0,0,0,0.3);display: flex;flex-direction: row;">
                          <div class="el-icon-map-location" style="font-size: 40px"></div>
                          <div style="margin-left: 20px;font-size: 16px">
                            <div style="font-size: 15px;font-weight: bold">地址</div>
                            <div style="font-size: 16px">{{setting.area.address}}</div>
                          </div>
                        </div>
                      </div>

                      <div style="flex: 1">
                        <div
                            style="align-items: center;border-radius: 10px;padding: 10px;height: 60px;box-shadow: 0 0 9px rgba(0,0,0,0.3);display: flex;flex-direction: row;width: 200px">
                          <div class="el-icon-message" style="font-size: 40px"></div>
                          <div style="margin-left: 20px;font-size: 16px">
                            <div style="font-size: 15px;font-weight: bold">电子邮箱</div>
                            <div>{{setting.area.email}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="padding: 20px">
                      <div id="container"
                           style="width: 100%;height: 400px;box-shadow: 0 0 9px rgba(0,0,0,0.3);border-radius: 10px"></div>
                    </div>

                    <div v-if="setting.contact_us" class="detail" v-html="setting.contact_us"></div>

                    <div v-else style="display: flex;flex-direction: row;justify-content: center;margin-top: 30px">
                      <div style="box-shadow: 0 0 9px rgba(0,0,0,0.3);border-radius: 10px;padding: 10px">
                        <img style="width: 200px" src="../images/gzhewm.jpg"/>
                        <div style="font-size: 16px;text-align: center">公众号</div>
                      </div>
                      <div style="box-shadow: 0 0 9px rgba(0,0,0,0.3);border-radius: 10px;padding: 10px;margin-left: 50px">
                        <img style="width: 200px" src="../images/kfewm.jpg"/>
                        <div style="font-size: 16px;text-align: center">客服号</div>
                      </div>
                    </div>
                  </div>
                  &lt;!&ndash;          <div>
                              <div id="container" style="width: 100%;height: 300px"></div>
                            </div>
                            <div class="detail" v-html="setting.contact_us" ></div>&ndash;&gt;

                </div>-->
      </div>
      <div v-if="type==2" class="contact" style="display: flex;flex-direction: column">
        <div class="contactposition">

          <div class="detail" v-html="setting.about_us"></div>

        </div>
      </div>
    </div>
    <footer1></footer1>
  </div>
</template>

<script>

//导航栏组件
import nav1 from '/src/components/nav/nav1'
//页脚组件
import footer1 from "../components/footer/footer1";
import {mapState} from "vuex";
import {getSetting} from "@/api/setting";

export default {
  computed: {
    ...mapState(['setting'])
  },
  components: {

    nav1,
    footer1,
  }
  , data() {
    return {
      map: ''
      , setting: ''
      , type: 1
    }

  },
  created() {
    // this.$store.commit('_getSetting');

    this.type = this.$route.query.type;

    this._getSetting();

    this.$nextTick(() => {

      // eslint-disable-next-line no-undef
      this.map = new AMap.Map('container', {
        zoom: 20,//级别
        viewMode: '3D'//使用3D视图
      });


    });

  },
  methods: {

    _getSetting() {


      getSetting().then(res => {

        this.setting = res;

        // eslint-disable-next-line no-undef
        var marker = new AMap.Marker({
          // eslint-disable-next-line no-undef
          position: new AMap.LngLat(res.area.lng, res.area.lat),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        });


        // 信息窗体的内容


// 创建 infoWindow 实例
        // eslint-disable-next-line no-undef
        var infoWindow = new AMap.InfoWindow({
          anchor: 'top-right',
          content: '<div>' +
              '<div style="font-size: 18px;font-weight: bold">' + res.area.title + '</div>' +
              '<div style="font-size: 16px">' + res.area.address + '</div>' +
              '</div>',
        });

        infoWindow.open(this.map, [res.area.lng, res.area.lat])


        this.map.setCenter([res.area.lng, res.area.lat]);

// 将创建的点标记添加到已有的地图实例：
        this.map.add(marker);


      });

    }

  },
  watch: {
    "$route"(to) {
      //请求数据的方法

      let type = to.query.type
      this.type = type;
      this.$nextTick(() => {

        // eslint-disable-next-line no-undef
        this.map = new AMap.Map('container', {
          zoom: 20,//级别
          viewMode: '3D'//使用3D视图
        });


      });
      this._getSetting();


    }
  }
}
</script>

<style scoped>
.contact {
  width: 100%;
}

@import "~@/css/pagetitle.css";
.map {
  margin-left: 6px;
  margin-right: 6px;
  height: 240px;
  background: center center;
  padding-bottom: 24px;
}

.contactposition {
  width: 917px;
  margin: 21px auto 27px auto;
  background: white;
}

.margin {
  width: 350px;
  margin: 26px auto 0 auto;
  font-weight: bold;
  color: gray;
}

.contact {
  border-bottom: 1px solid #eeeeee;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  color: #666666;
  font-weight: 400;
}

.contact:first-of-type {
  padding-top: 0;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.element1 {
  text-align: left;
  text-decoration: none;
}

.element2 {
  text-align: right;
  color: #999999;
  font-weight: normal;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.keycode-unit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.keycode1, .keycode2 {
  width: 120px;
  height: 120px;
  border: 1px solid #dddddd;
  margin-bottom: 7px;
}

.keycode1-img, .keycode2-img {
  width: 100%;
  height: 100%;
  display: block;
}

.keycode {
  display: flex;
  padding: 10px;
  flex-direction: row;
  justify-content: space-between;
}

.icon-icon_QQ-chunselogo {
  font-size: 20px;
  color: deepskyblue;
  margin-right: 5px;
}

.keycode-title {
  font-size: 12px;
  color: #999999;
  font-weight: 400;
}

.zdh {
  padding: 20px 20px;
  box-sizing: border-box;
  width: 280px;
  color: white;
  position: absolute;
  left: -700px;
  opacity:0;

  animation: example 1s forwards;
}


@keyframes example {
  from {
    left: -700px;
    opacity:0;

  }
  to {
    left: 0;
    opacity:1;

  }
}

.ydh{

  position: absolute;
  right: -900px;
  opacity:1;


  animation: cccc 1s forwards;

}


@keyframes cccc {
  from {
    right: -900px;
    opacity:0;

  }
  to {
    right: 0;
    opacity:1;

  }
}
</style>
