import request from '../utils/request'


export function getSetting(params) {

    return request({

        url: 'setting'
        , method: 'get'
        , params

    })


}
